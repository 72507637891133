import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

const PrecosPage = () => {

  useEffect(() => {
     AOS.init()
  }, []);

  return (
    <main>
      <Seo
        title="Planos e Preços 💳 | Controle Completo de Estoque, Vendas e Financeiro"
		description="Explore os planos acessíveis do wEstoque para controle completo de estoque, vendas, financeiro e fiscal. Sem limites de usuários ou cadastros, com opções para múltiplas empresas e estoques. Experimente grátis por 7 dias e descubra como nosso sistema pode otimizar sua gestão com um custo acessível."
		keywords={[
			`Sistema de controle de estoque`,
			`Gestão de PDV online`,
			`ERP para múltiplas lojas`,
			`Controle financeiro e fiscal`,
			`Gestão de vendas e estoques`,
			`Emissão de notas fiscais`,
			`Software de gestão empresarial`,
			`Controle de múltiplos caixas`,
			`Gestão integrada de empresas`,
			`PDV online`, 
			`controle de vendas`, 
			`gestão financeira e fiscal`, 
			`controle de múltiplas empresas`, 
			`gerenciamento de múltiplos estoques`, 
			`sistema ERP`, 
			`preços acessíveis`, 
			`teste grátis wEstoque`, 
			`sem limite de usuários`, 
			`sistema completo de gestão`, 
			`gestão de filiais e franquias`,
			`sistema westoque`,
			`westoque`,
			`preços`,
			`planos`,
			`módulos`,
			`sem limitações`,
			`sem limite usuários`,
			`controle de estoque`,
			`sistema de controle de estoque`,
			`pdv`,
			`pdv web`,
			`pdv online`,
			`pdv online web sistema de controle de estoque eficiente e barato`,
			`sistema de controle de estoque eficiente`,
		]}
      />

      <Header />
 
      <section style={{paddingTop: `100px`}} className="overflow-x-hidden">

        <div className="max-w-6xl mx-auto">

        	<div className="py-8 md:py-15">
            
            <div className="grid gap-3 sm:gap-8">
                
							<div className="md:grid md:grid-cols-12 md:gap-6 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 aos-init aos-animate" data-aos="fade-in">
                                    <div className="max-w-4xl mx-auto text-center pb-12 md:pb-16">
										<h1 className="text-center text-5xl font-bold" >
                                            Preço simplificado e sem surpresas.
										</h1>
										<p className="text-md text-gray-700 mb-15 pt-2">Controle sua loja / operação de forma eficiente e inteligente pagando um preço acessível por empresa ativada e sem limitações.</p><br/><br/>
                                        <p className="text-md text-gray-700 mb-4">
                                            pague apenas<br/>
                                            <span className="text-3xl text-gray-700 mb-4 font-bold">R$ 149,00</span><br/>
											por mês e sem limites<br/>
                                        </p>
                                        <br/>
                                        <a href="/usar" className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-3 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out">TESTAR POR 7 DIAS</a>
                                        
                                        <p className="text-sm text-gray-700 mb-15 mt-12">NÃO PRECISA de 💳&nbsp; Cartão de Crédito ou Pagamentos Antecipados!<br/>Teste sem compromisso...</p>
									</div>
									
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate -mt-20 xs:-mt-10 " data-aos="fade-right">
									<br className="md:hidden"/>
                                    <StaticImage
                                        src="../images/icone-planos.png"
										alt="Sistema wEstoque - PDV online, financeiro e fiscal"
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={180}
										className="max-w-full mx-auto h-auto"
									/>
                                    <div className="font-semibold text-xl text-gray-700 ml-10 md:ml-32">
                                        <br/>
                                        💁🏻&nbsp; Usuários ilimitados<br/>
                                        🗃&nbsp; Sem limite de cadastros<br/>
                                        🗒&nbsp; Emissão de NFC-e & NF-e<br/>
                                        📦&nbsp; Múltiplos estoques<br/>
                                        🏠&nbsp; Gestão de filiais / franquia<br/>
                                        🖥&nbsp; PDV online web<br/>
                                        🥇&nbsp; Controle de comissões<br/>
                                        💰&nbsp; Gestão financeira<br/>
                                    </div>
                                    
								</div>
							</div>


                            <div className="grid grid-cols-1 text-center aos-init aos-animate p-3" data-aos="fade-in">
								<div className="text-3xl text-purple-600 mb-2"><b>Amplie seu controle</b> e melhore sua gestão com <b>módulos</b> úteis</div>
							</div>

                            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3">

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0 text-center" data-aos="fade-left">
									<StaticImage
										src="../images/icone-fiscal.png"
										alt="Emita notas fiscais e controle seus dados."
										placeholder="blurred"
										layout="fixed"
										quality={100}
                                        className="mx-auto"
										
									/>
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-westoque-400 text-2xl">Fiscal</span>
                                        <p><b>+ R$ 49,00/empresa (mensal)</b></p><br/>
										<p className="text-gray-700 text-md">Emita NFC-e e NF-e ilimitadas em poucos cliques. Você pode emitir quantas notas quiser pagando pouco por isso.</p>
									</div>
								</div>

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0 text-center" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-multiempresas.png"
										alt="Controle de Ponto de Venda e Multiplas Empresas e Lojas. Use diversos caixas."
										placeholder="blurred"
										layout="fixed"
										quality={100}
                                        className="mx-auto"
									/>
									<div className="mt-4 mb-10">
                                        <span className="bg-clip-text text-westoque-400 text-2xl">Multi Empresa</span>
                                        <p><b>+ R$ 149,00/empresa (mensal)</b></p><br/>
										<p className="text-gray-700 text-md">Crie e gerencie estruturas de lojas / empresas de um único sistema... Controle vários CNPJ's facilmente.</p>
									</div>
								</div>


								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0 text-center" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-controleestoque.png"
										alt="Gerencie mais de um estoque e localidade com o sistema. Faça venda de estoques diferentes."
										placeholder="blurred"
										layout="fixed"
										quality={100}
                                        className="mx-auto"
									/>
									
									<div className="mt-4 mb-10">
                                        <span className="bg-clip-text text-westoque-400 text-2xl">Estoque Adicional</span>
                                        <p><b>+ R$ 15,00/estoque (mensal)</b></p><br/>
										<p className="text-gray-700 text-md">Precisa controlar multiplos estoques em uma única estrutura? É possível criar quantos estoques quiser.</p>
									</div>
								</div>

							</div>

                            <div className="grid grid-cols-1 text-center aos-init aos-animate p-2" data-aos="fade-in">
								<div className="text-3xl text-purple-600 mb-2">O <b>sucesso</b> da sua empresa <b>está aqui</b>!</div>
                                <p className="font-semibold text-xl text-gray-700 mb-2">Nosso sistema inclui:</p>
							</div>  

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left">
									<StaticImage
										src="../images/icone-multiempresas.png"
										alt="Controle multiplas empresas e lojas. Venda e gerencie todas as suas lojas online."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={78}
									/>
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-westoque-400 text-3xl">Multi Lojas</span>
										<p className="text-gray-700 text-xl">Controle o estoque, vendas e representantes de todas suas empresas em um único sistema.</p>
									</div>
								</div>

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-pdv.png"
										alt="Controle de Ponto de Venda e Caixas. Use diversos caixas."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										
									/>
									
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-indigo-700 text-3xl">Vendas e PDV</span>
										<p className="text-gray-700 text-xl">Controle orçamentos, vendas, ordens de serviço, comissões de vendedores, e muito mais.</p>
									</div>
								</div>


								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-controlefinanceiro.png"
										alt="Controle Financeiro. Gerencie suas contas e receitas."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={81}
									/>
									
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-transparent text-green-700 text-3xl">Financeiro</span>
										<p className="text-gray-700 text-xl">Gerencie custos e planeje seu financeiro com precisão.</p>
									</div>
								</div>


								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-controleestoque.png"
										alt="Gerencie vários estoques e localidades. Venda de diversos lugares ao mesmo tempo."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										height={75}
									/>
									
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-transparent text-purple-700 text-3xl">Estoques</span>
										<p className="text-gray-700 text-xl">Gerencie produtos, pedidos, compras, movimentações, transferências, relatórios e muito mais.</p>
									</div>
								</div>

							</div>

							<div className="md:grid md:grid-cols-12 md:gap-6 items-center mt-16">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">
                  					<StaticImage
										src="../images/call-pdv-queijos.png"
										alt="Controle sua loja com o sistema wEstoque. Venda por unidade ou por peso!"
										placeholder="blurred"
										layout="fullWidth"
										quality={100}
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-6 md:pb-8">
										<h1 className="text-center text-5xl font-bold mb-2 p-1" >
											<span className="underline decoration-westoque-400">Sistema Completo</span> e <span className="underline decoration-westoque-400">Eficiente</span> para<br/><span className="underline decoration-sky-500">Sua Empresa</span>!
										</h1> 
										<div className="text-xl text-gray-700 mb-2 w-full">Com o <b className="text-2xl">w<span className="text-westoque-400">Estoque</span></b> você pode focar no seu negócio!</div>
									</div>
									<a className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out" href="/usar ">EXPERIMENTE GRÁTIS</a>
								</div>
							</div>


						</div>
					</div>
				</div>
			</section>

      		<br/><br/>
			<Footer />

    </main>
  )
}

export default PrecosPage
